import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent {
  @ViewChild(MatRipple, {static: false}) ripple: MatRipple;

  @Input()
  entry: any;

  constructor() { }

  animate() {
    this.ripple.launch({persistent: false, centered: true});
  }

}
