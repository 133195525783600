import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { EntryComponent } from './entry/entry.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChildren(EntryComponent) entries!: QueryList<EntryComponent>;

  alphabet = [
    {caption: '3', active: false},
    {caption: '4', active: false},
    {caption: '5', active: false},
    {caption: '6', active: false},
    {caption: '7', active: false},
    {caption: 's', active: false},
    {caption: 'd', active: false},
    {caption: 'f', active: false},
    {caption: 'g', active: false},
    {caption: 'h', active: false},
    {caption: 'y', active: false},
    {caption: 'm', active: false},
  ];
  rounds = [];
  result: Result;
  gameStarted: boolean;
  gameInterval: any;
  time = 0;
  currentRound: number;
  maxRounds = 30;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.gameStarted) {
      const button = this.alphabet.filter(item => item.caption === event.key)[0];
      this.entries.filter(e => e.entry.caption === event.key).map(key => key.animate());
      if (button) {
        this.select(button);
      }
    }
  }

  select(button: any) {
    this.stopTimer();
    this.currentRound++;
    this.rounds.push({right: button.active, time: this.time});
    if (this.currentRound > 30) {
      this.endGame();
      return;
    }
    this.resetAlphabet();
    this.selectRandom();
    this.startTimer();
  }

  resetAlphabet() {
    this.alphabet.forEach(entry => entry.active = false);
  }

  selectRandom() {
    const randomNumber = Math.round(Math.random() * (this.alphabet.length - 1));
    this.alphabet[randomNumber].active = true;
  }

  startTimer() {
    this.time = 0;
    this.gameInterval = setInterval(() => {
      this.time++;
    }, 100);
  }

  stopTimer() {
    clearInterval(this.gameInterval);
  }

  newGame() {
    this.gameStarted = true;
    this.result = undefined;
    this.rounds = [];
    this.resetAlphabet();
    this.selectRandom();
    this.currentRound = 1;
    this.startTimer();
  }

  endGame() {
    this.gameStarted = false;
    let averageTime = 0;
    this.rounds.map(round => {
      averageTime += round.time;
    });
    this.result = {
      rightAnswers: this.rounds.filter(round => round.right).length,
      averageTime: Math.round(((averageTime / this.maxRounds) / 10) * 100) / 100,
      quota: Math.round((this.rounds.filter(round => round.right).length / this.maxRounds) * 100),
    };
  }
}

export interface Result {
  rightAnswers: number;
  averageTime: number;
  quota: number;
}
